import { RepairType } from "omni-neo-api";
import React, { FC, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    Button,
    ContentHeader,
    Form,
    InputPair,
    Link,
    RadioGroup,
    Separator,
    TextArea,
    TextInput
} from "../../..";
import { OmniAPIContext } from "../../../../contexts";

import "./repairs-create.css";

type RepairsCreateProps = {
    style?: string[];
};

export const RepairsCreate: FC<RepairsCreateProps> = ({ style = [] }) => {
    const classes = useMemo(
        () => ["repairs-create", ...style].join(" "),
        [style]
    );

    const navigate = useNavigate();
    const api = useContext(OmniAPIContext);
    const [title, setTitle] = useState<string | undefined>();
    const [customer, setCustomer] = useState<string | undefined>();
    const [supplier, setSupplier] = useState<string | undefined>();
    const [itemReference, setItemReference] = useState<string | undefined>();
    const [saleIdentifier, setSaleIdentifier] = useState<string | undefined>();
    const [repairType, setRepairType] = useState<RepairType | undefined>();
    const [itemDamage, setItemDamage] = useState<string | undefined>();
    const [problemDescription, setProblemDescription] = useState<
        string | undefined
    >();
    const [description, setDescription] = useState<string | undefined>();

    const submit = async () => {
        if (!title) {
            throw new Error("mandatory field is not present");
        }
        const repair = await api.createRepair({
            title: title ?? "unknown",
            customer: customer,
            supplier: supplier,
            item_reference: itemReference,
            sale_identifier: saleIdentifier,
            repair_type: repairType,
            item_damage: itemDamage,
            problem_description: problemDescription,
            description: description
        });

        navigate(`/repairs/${repair.object_id}`);
    };

    return (
        <div className={classes}>
            <Form onSubmit={() => submit()}>
                <ContentHeader title="New Repair" style={["compressed"]} />
                <InputPair mandatory={true} label="Title">
                    <TextInput
                        placeholder="The title of the repair"
                        onChange={(v) => setTitle(v)}
                    />
                </InputPair>
                <InputPair label="Customer">
                    <TextInput onChange={(v) => setCustomer(v)} />
                </InputPair>
                <InputPair label="Supplier">
                    <TextInput onChange={(v) => setSupplier(v)} />
                </InputPair>
                <Separator />
                <InputPair label="Item Reference">
                    <TextInput
                        placeholder="Internal reference of the item"
                        onChange={(v) => setItemReference(v)}
                    />
                </InputPair>
                <InputPair label="Sale Identifier">
                    <TextInput
                        placeholder="The identifier of the sale"
                        onChange={(v) => setSaleIdentifier(v)}
                    />
                </InputPair>
                <InputPair label="Repair Type">
                    <RadioGroup
                        name="repair-type"
                        items={[
                            {
                                value: "waranty",
                                text: "Waranty",
                                checked: true
                            },
                            { value: "quotation", text: "Quotation" }
                        ]}
                        onChange={(i) => setRepairType(i.value as RepairType)}
                    />
                </InputPair>
                <Separator />
                <InputPair label="Item Damage">
                    <TextArea
                        placeholder="A description of the item's damage"
                        onChange={(v) => setItemDamage(v)}
                    />
                </InputPair>
                <InputPair label="Problem Desc.">
                    <TextArea
                        placeholder="A small description of the item's problem, tell by the customer"
                        onChange={(v) => setProblemDescription(v)}
                    />
                </InputPair>
                <InputPair label="Description">
                    <TextArea
                        placeholder="A small description of the repair"
                        onChange={(v) => setDescription(v)}
                    />
                </InputPair>
                <Separator />
                <Button onClick={() => submit()}>Save</Button>
                <span style={{ padding: "0px 16px 0px 16px" }}>or</span>
                <Link to="/repairs" color="red">
                    Cancel
                </Link>
            </Form>
        </div>
    );
};

export default RepairsCreate;
