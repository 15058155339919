import React, { FC, ReactNode, useMemo } from "react";

import "./padding.css";

type PaddingProps = {
    children: ReactNode;
    padding: string | number;
    style?: string[];
};

export const Padding: FC<PaddingProps> = ({
    children,
    padding,
    style = []
}) => {
    const classes = useMemo(() => ["padding", ...style].join(" "), [style]);
    return (
        <div className={classes} style={{ padding: padding }}>
            {children}
        </div>
    );
};

export default Padding;
