import { Repair } from "omni-neo-api";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";

import { ContentHeader, Link, Text } from "../../..";
import { OmniAPIContext } from "../../../../contexts";

import "./repairs-list.css";

type RepairsListProps = {
    style?: string[];
};

export const RepairsList: FC<RepairsListProps> = ({ style = [] }) => {
    const classes = useMemo(
        () => ["repairs-list", ...style].join(" "),
        [style]
    );

    const api = useContext(OmniAPIContext);
    const [repairs, setRepairs] = useState<Repair[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const repairs = await api.listRepairs({ params: { limit: 0 } });
            setRepairs(repairs);
        };
        fetchData();
    }, [api]);

    return (
        <div className={classes}>
            <ContentHeader title={"Repairs"} style={["compressed"]} />
            <Text>
                {repairs.map((r, i) => (
                    <div className="item" key={i}>
                        <Link to={`/repairs/${r.object_id}`}>{r.title}</Link>
                    </div>
                ))}
            </Text>
        </div>
    );
};

export default RepairsList;
