import React, { FC, useMemo } from "react";

import "./text-area.css";

type TextAreaProps = {
    placeholder?: string;
    type?: string;
    size?: "medium" | "large";
    width?: string | number;
    style?: string[];
    onChange?: (value: string) => void;
};

export const TextArea: FC<TextAreaProps> = ({
    placeholder,
    size,
    width,
    style = [],
    onChange
}) => {
    const classes = useMemo(
        () => ["text-area", size ? size : "", ...style].join(" "),
        [size, style]
    );
    return (
        <textarea
            className={classes}
            style={{ width: width }}
            placeholder={placeholder}
            onChange={(e) => onChange && onChange(e.target.value)}
        />
    );
};

export default TextArea;
