import React, { FC, useMemo } from "react";

import "./separator.css";

type SeparatorProps = {
    style?: string[];
};

export const Separator: FC<SeparatorProps> = ({ style = [] }) => {
    const classes = useMemo(() => ["separator", ...style].join(" "), [style]);
    return <div className={classes} />;
};

export default Separator;
