import React, { FC, useMemo } from "react";

import { Link } from "../..";

import "./footer.css";

type FooterStyle = "dark";

type FooterProps = {
    copyright?: string;
    copyrightHref?: string;
    languages?: string[];
    warning?: boolean;
    renderTime?: number;
    style?: FooterStyle[];
};

export const Footer: FC<FooterProps> = ({
    copyright,
    copyrightHref,
    languages,
    warning = false,
    renderTime,
    style = []
}) => {
    const classes = useMemo(() => ["footer", ...style].join(" "), [style]);
    return (
        <div className={classes}>
            {copyright ? (
                <div className="footer-message">
                    <span>© Copyright</span>
                    {copyrightHref ? (
                        <Link href={copyrightHref} target="_blank">
                            {copyright}
                        </Link>
                    ) : (
                        <span className="value">{copyright}</span>
                    )}
                </div>
            ) : (
                ""
            )}
            {languages ? (
                <div className="footer-language">
                    {languages.map((l, i) => (
                        <Link key={i} selected={i === 0}>
                            {l}
                        </Link>
                    ))}
                </div>
            ) : (
                ""
            )}
            {warning ? (
                <div className="footer-warning">
                    <Link href="#">Lugar das Jóias, Ourivesaria Lda.</Link>{" "}
                    running under <Link href="#">development</Link>
                </div>
            ) : (
                ""
            )}
            {renderTime !== undefined ? (
                <div className="footer-timing">
                    Processed and rendered in {renderTime} ms
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export default Footer;
