import React, { FC, ReactNode, useMemo } from "react";

import "./side-bar.css";

type SideBarProps = {
    children?: ReactNode;
    style?: string[];
};

export const SideBar: FC<SideBarProps> = ({ children, style = [] }) => {
    const classes = useMemo(() => ["side-bar", ...style].join(" "), [style]);
    return <div className={classes}>{children}</div>;
};

export default SideBar;
