import React, { FC, ReactNode, useMemo } from "react";

import "./form.css";

type FormProps = {
    children?: ReactNode;
    style?: string[];
    onSubmit?: () => void;
};

export const Form: FC<FormProps> = ({ children, style = [], onSubmit }) => {
    const classes = useMemo(() => ["form", ...style].join(" "), [style]);
    return (
        <form
            className={classes}
            onSubmit={(e) => {
                onSubmit && onSubmit();
                e.preventDefault();
            }}
        >
            {children}
            <input className="submit" type="submit" />
        </form>
    );
};

export default Form;
