import React, { FC } from "react";

import { Paragraph, QuickAction } from "../../..";

export const RepairsCreateSide: FC = () => {
    return (
        <>
            <QuickAction title="Quick Help">
                <Paragraph style={["condensed"]}>
                    Repairs are operations that describe a repair operation to
                    be handled internally or by a third party.
                </Paragraph>
            </QuickAction>
        </>
    );
};

export default RepairsCreateSide;
