import React, { FC, useMemo } from "react";

import { Avatar, Label, Separator, Title } from "../..";

import "./content-header.css";

type ContentHeaderProps = {
    title?: string;
    subTitle?: string;
    avatarSrc?: string;
    style?: string[];
};

export const ContentHeader: FC<ContentHeaderProps> = ({
    title,
    subTitle,
    avatarSrc,
    style = []
}) => {
    const classes = useMemo(
        () => ["content-header", ...style].join(" "),
        [style]
    );
    return (
        <div className={classes}>
            <div className="header-contents">
                <div className="left">
                    {avatarSrc ? <Avatar src={avatarSrc} /> : ""}
                </div>
                <div className="right">
                    {title ? <Title>{title}</Title> : ""}
                    {subTitle ? (
                        <Label style={["light"]}>{subTitle}</Label>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <Separator />
        </div>
    );
};

export default ContentHeader;
