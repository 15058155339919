import React, { FC } from "react";

import { Link, List, Paragraph, QuickAction, Text } from "../../..";

export const RepairsListSide: FC = () => {
    return (
        <>
            <QuickAction title="Actions">
                <Text>
                    <List
                        elements={[
                            <Link to="/repairs/new">Create Repair</Link>
                        ]}
                    />
                </Text>
            </QuickAction>
            <QuickAction title="Quick Help">
                <Paragraph style={["condensed"]}>
                    Repairs are operations that describe a repair operation to
                    be handled internally or by a third party.
                </Paragraph>
            </QuickAction>
        </>
    );
};

export default RepairsListSide;
