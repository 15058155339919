import React, { FC, ReactNode, useMemo } from "react";

import "./title.css";

type TitleProps = {
    children?: ReactNode;
    style?: string[];
};

export const Title: FC<TitleProps> = ({ children, style = [] }) => {
    const classes = useMemo(() => ["title", ...style].join(" "), [style]);
    return <h1 className={classes}>{children}</h1>;
};

export default Title;
