import React, { FC, ReactNode, useMemo } from "react";

import "./paragraph.css";

type ParagraphProps = {
    children: ReactNode;
    style?: string[];
};

export const Paragraph: FC<ParagraphProps> = ({ children, style = [] }) => {
    const classes = useMemo(() => ["paragraph", ...style].join(" "), [style]);
    return <p className={classes}>{children}</p>;
};

export default Paragraph;
