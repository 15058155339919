import React, { FC, ReactNode, useMemo } from "react";

import "./top-bar.css";

type TopBarProps = {
    children?: ReactNode;
    width?: string | number;
    style?: string[];
};

export const TopBar: FC<TopBarProps> = ({ children, width, style = [] }) => {
    const classes = useMemo(() => ["top-bar", ...style].join(" "), [style]);
    return (
        <div className={classes}>
            <div className="top-bar-container" style={{ width: width }}>
                {children}
            </div>
        </div>
    );
};

export default TopBar;
