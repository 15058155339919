import React, { FC, useMemo, useState } from "react";

import "./check-field.css";

type CheckFieldProps = {
    name?: string;
    checked?: boolean;
    managed?: boolean;
    style?: string[];
    onChange?: (checked: boolean) => void;
};

export const CheckField: FC<CheckFieldProps> = ({
    name,
    checked = false,
    managed = true,
    style = [],
    onChange
}) => {
    const classes = useMemo(() => ["check-field", ...style].join(" "), [style]);
    const [checkedState, setCheckedState] = useState<boolean>(checked);
    return (
        <input
            type="checkbox"
            name={name}
            checked={managed ? checkedState : checked}
            className={classes}
            onChange={(e) => {
                setCheckedState(e.target.checked);
                onChange && onChange(e.target.checked);
            }}
        />
    );
};

export default CheckField;
