import React, {
    FC,
    HTMLAttributeAnchorTarget,
    ReactNode,
    useMemo
} from "react";
import { Link as LinkRouter } from "react-router-dom";

import "./link.css";

type LinkStyle = "strong" | "block" | "no-style" | "no-select";

type LinkProps = {
    children: ReactNode;
    to?: string;
    href?: string;
    target?: HTMLAttributeAnchorTarget;
    color?:
        | "blue"
        | "green"
        | "red"
        | "grey"
        | "grey-light"
        | "grey-dark"
        | "grey-darker";
    selected?: boolean;
    style?: LinkStyle[];
    onClick?: () => void;
};

export const Link: FC<LinkProps> = ({
    children,
    to,
    href,
    target,
    color,
    selected = false,
    style = [],
    onClick
}) => {
    const classes = useMemo(
        () =>
            [
                to ? "link-container" : "link",
                color ? color : "",
                selected ? "selected" : "",
                ...style
            ].join(" "),
        [to, color, selected, style]
    );
    return to ? (
        <span className={classes} onClick={onClick}>
            <LinkRouter to={to} target={target}>
                {children}
            </LinkRouter>
        </span>
    ) : (
        <a className={classes} href={href} target={target} onClick={onClick}>
            {children}
        </a>
    );
};

export default Link;
