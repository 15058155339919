import React, { FC, ReactNode, useMemo } from "react";

import "./block.css";

type BlockStyle = "centered";

type BlockProps = {
    children: ReactNode;
    padding?: string | number;
    margin?: string | number;
    style?: BlockStyle[];
};

export const Block: FC<BlockProps> = ({
    children,
    padding,
    margin,
    style = []
}) => {
    const classes = useMemo(() => ["block", ...style].join(" "), [style]);
    return (
        <div className={classes} style={{ padding: padding, margin: margin }}>
            {children}
        </div>
    );
};

export default Block;
