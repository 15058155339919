import React from "react";

type Data = {
    data: unknown;
    setData?: (data: unknown) => void;
    reloadData?: () => void;
    setReloadData?: (reloadData: () => void) => void;
};

export const DataContext = React.createContext<Data>({
    data: null
});

export default DataContext;
