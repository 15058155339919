import { Repair } from "omni-neo-api";
import React, { FC, useContext } from "react";
import { useParams } from "react-router-dom";

import { Link, List, Paragraph, QuickAction, Text } from "../../..";
import { DataContext, OmniAPIContext } from "../../../../contexts";

export const RepairsShowSide: FC = () => {
    const { id } = useParams();
    const api = useContext(OmniAPIContext);
    const { data, reloadData } = useContext(DataContext);
    const repair = data ? (data as Repair) : null;

    const importOmni = async () => {
        await api.importRepair(Number(id));
        reloadData?.();
    };

    const getElements = () => {
        const elements = [];
        if (repair?.imported === false) {
            elements.push(<Link onClick={importOmni}>Import</Link>);
        }
        if (repair?.reference_oid) {
            elements.push(
                <Link
                    href={`${process.env.OMNI_WEB_URL}omni_sam/repairs/${repair?.reference_oid}`}
                    target="_blank"
                >
                    Reference
                </Link>
            );
        }
        elements.push(
            <Link href="#" color="red">
                Delete
            </Link>
        );
        return elements;
    };

    return (
        <>
            <QuickAction title="Actions">
                <Text>
                    <List elements={getElements()} />
                </Text>
            </QuickAction>
            <QuickAction title="Quick Help">
                <Paragraph style={["condensed"]}>
                    Repairs are operations that describe a repair operation to
                    be handled internally or by a third party.
                </Paragraph>
            </QuickAction>
        </>
    );
};

export default RepairsShowSide;
