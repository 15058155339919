import React, { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { Link } from "../..";

import "./side-menu.css";

type SideMenuProps = {
    style?: string[];
};

export const SideMenu: FC<SideMenuProps> = ({ style = [] }) => {
    const classes = useMemo(() => ["side-menu", ...style].join(" "), [style]);
    const location = useLocation();
    return (
        <ul className={classes}>
            <li
                className={
                    location.pathname.startsWith("/repairs")
                        ? "selected"
                        : undefined
                }
            >
                <Link style={["block", "no-style"]} to="/repairs">
                    Repairs {}
                </Link>
            </li>
            <li
                className={
                    location.pathname.startsWith("/returns")
                        ? "selected"
                        : undefined
                }
            >
                <Link style={["block", "no-style"]} href="#">
                    Returns
                </Link>
            </li>
        </ul>
    );
};

export default SideMenu;
