import { API as OmniAPI } from "omni-neo-api";
import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import {
    BrowserRouter,
    Navigate,
    Route,
    Routes,
    useNavigate
} from "react-router-dom";

import {
    Block,
    Button,
    CheckPair,
    Content,
    ContentPanel,
    ContextBar,
    ErrorBox,
    Footer,
    Form,
    HeaderLogo,
    Home,
    Image,
    InputPair,
    Label,
    Link,
    MainContainer,
    Padding,
    RepairsCreate,
    RepairsCreateSide,
    RepairsList,
    RepairsListSide,
    RepairsShow,
    RepairsShowSide,
    Separator,
    SideBar,
    SideMenu,
    TextInput,
    Title,
    TopBar,
    Viewport
} from "./components";
import { DataContext, OmniAPIContext } from "./contexts";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const require: any;

type AppOptions = {
    width?: string | number;
};

export const MainPanel: FC<AppOptions> = ({ width = 960 }) => {
    const api = useContext(OmniAPIContext);
    return (
        <Viewport width={width}>
            <TopBar width={width}>
                <HeaderLogo
                    warning={true}
                    logoSrc={require("../res/header-logo.png")}
                    style={["float"]}
                />
                <Padding padding="0px 12px 0px 4px">
                    <Button to="/repairs/new">Create Repair</Button>
                </Padding>
                <TextInput
                    size="large"
                    width={380}
                    placeholder="Pesquise lojas, funcionários, utilizadores, etc."
                ></TextInput>
                <div style={{ float: "right" }}>
                    <Link style={["strong", "no-select"]} to="/logout">
                        {api.username}
                    </Link>
                </div>
            </TopBar>
            <MainContainer
                left={
                    <SideBar>
                        <Routes>
                            <Route path="/*" element={<SideMenu />} />
                        </Routes>
                    </SideBar>
                }
                right={
                    <ContextBar>
                        <Routes>
                            <Route
                                path="/repairs"
                                element={<RepairsListSide />}
                            />
                            <Route
                                path="/repairs/new"
                                element={<RepairsCreateSide />}
                            />
                            <Route
                                path="/repairs/:id"
                                element={<RepairsShowSide />}
                            />
                            <Route path="/*" element={<></>} />
                        </Routes>
                    </ContextBar>
                }
            >
                <Content>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/repairs" element={<RepairsList />} />
                        <Route
                            path="/repairs/new"
                            element={<RepairsCreate />}
                        />
                        <Route path="/repairs/:id" element={<RepairsShow />} />
                    </Routes>
                </Content>
            </MainContainer>
            <Footer
                copyright="Hive Solutions"
                copyrightHref="https://www.hive.pt"
                languages={["English", "Portuguese"]}
                warning={true}
                renderTime={234}
            />
        </Viewport>
    );
};

export const LoginPanel: FC<AppOptions> = ({ width = 560 }) => {
    const navigate = useNavigate();
    const api = useContext(OmniAPIContext);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [username, setUsername] = useState<string | undefined>();
    const [password, setPassword] = useState<string | undefined>();
    const [error, setError] = useState<Error | undefined>();

    const submit = async () => {
        setSubmitting(true);

        try {
            if (!username || !password) {
                throw new Error("an username and a password must be provided");
            }

            await api.login(username, password);

            localStorage.setItem("username", api.username || "");
            localStorage.setItem("sessionId", api.sessionId || "");

            //@todo this navigation should respect a next value
            navigate(`/`);
        } catch (err) {
            setError(err);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Viewport style={["dark", "centered"]}>
            <Block padding="60px 0px 0px 0px">
                <Image
                    src={require("../res/login-logo-dark.png")}
                    width={151}
                />
            </Block>
            <Block padding="60px 0px 60px 0px">
                <ContentPanel width={width} style={["round", "dark"]}>
                    <Form onSubmit={() => submit()}>
                        <Title>Iniciar sessão</Title>
                        <Separator></Separator>
                        {error ? (
                            <ErrorBox
                                title="There was a problem with your submission"
                                description={error.message}
                            />
                        ) : (
                            <></>
                        )}
                        <InputPair left={<Label align="right">Username</Label>}>
                            <TextInput onChange={(v) => setUsername(v)} />
                        </InputPair>
                        <InputPair left={<Label align="right">Password</Label>}>
                            <TextInput
                                type="password"
                                onChange={(v) => setPassword(v)}
                            />
                        </InputPair>
                        <InputPair>
                            <CheckPair checked={true}>Stay signed in</CheckPair>
                        </InputPair>
                        <Block margin="20px 0px 0px 0px" style={["centered"]}>
                            <Button
                                disabled={submitting}
                                width={116}
                                onClick={() => submit()}
                            >
                                {submitting ? "Logging in" : "Login"}
                            </Button>
                        </Block>
                    </Form>
                </ContentPanel>
            </Block>
            <Footer
                copyright="Hive Solutions"
                copyrightHref="https://www.hive.pt"
                languages={["English", "Portuguese"]}
                warning={true}
                renderTime={234}
                style={["dark"]}
            />
        </Viewport>
    );
};

export const LogoutPanel: FC<AppOptions> = () => {
    const navigate = useNavigate();
    const api = useContext(OmniAPIContext);

    useEffect(() => {
        api.username = null;
        api.sessionId = null;

        localStorage.setItem("username", "");
        localStorage.setItem("sessionId", "");

        //@todo this navigation should respect a next value
        navigate(`/`);
    }, [api, navigate]);

    return <></>;
};

type ProtectedRouteProps = {
    children: ReactNode;
};

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
    const api = useContext(OmniAPIContext);

    if (!api.sessionId) {
        return <Navigate to="/login" replace />;
    }

    return <>{children}</>;
};

export const App: FC<AppOptions> = () => {
    const [api] = useState<OmniAPI>(
        new OmniAPI({
            baseUrl:
                process.env.OMNI_BASE_URL ??
                "https://omni-gateway-ldj.bemisc.com/",
            username: localStorage.getItem("username"),
            sessionId: localStorage.getItem("sessionId")
        })
    );

    const [data, setData] = useState<unknown>(null);
    const [reloadData, setReloadData] = useState<(() => void) | undefined>();
    const dataValue = {
        data: data,
        setData: setData,
        reloadData: reloadData,
        setReloadData: setReloadData
    };

    return (
        <OmniAPIContext.Provider value={api}>
            <DataContext.Provider value={dataValue}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={<LoginPanel />} />
                        <Route path="/logout" element={<LogoutPanel />} />
                        <Route
                            path="/*"
                            element={
                                <ProtectedRoute>
                                    <MainPanel />
                                </ProtectedRoute>
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </DataContext.Provider>
        </OmniAPIContext.Provider>
    );
};

export const startApp = (
    element: string,
    { width = 960 }: AppOptions = { width: 960 }
) => {
    const elementRef = document.getElementById(element);
    if (!elementRef) return;

    const root = ReactDOM.createRoot(elementRef);
    root.render(
        <React.StrictMode>
            <App width={width} />
        </React.StrictMode>
    );
};
