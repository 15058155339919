import React, { FC, ReactNode, useMemo } from "react";

import "./content-panel.css";

type ContentPanelStyle = "round" | "dark" | "centered";

type ContentPanelProps = {
    children?: ReactNode;
    width?: string | number;
    style?: ContentPanelStyle[];
};

export const ContentPanel: FC<ContentPanelProps> = ({
    children,
    width,
    style = []
}) => {
    const classes = useMemo(
        () => ["content-panel", ...style].join(" "),
        [style]
    );
    return (
        <div className={classes} style={{ width: width }}>
            {children}
        </div>
    );
};

export default ContentPanel;
