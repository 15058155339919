import React, { FC, useMemo } from "react";

import "./error-box.css";

type ErrorBoxProps = {
    title: string;
    description: string;
    style?: string[];
};

export const ErrorBox: FC<ErrorBoxProps> = ({
    title,
    description,
    style = []
}) => {
    const classes = useMemo(() => ["error-box", ...style].join(" "), [style]);
    return (
        <div className={classes}>
            <div className="error-title">{title}</div>
            <div className="error-description">{description}</div>
        </div>
    );
};

export default ErrorBox;
