import React, { FC, ReactNode, useMemo } from "react";

import { Link } from "../link/link";

import "./header-logo.css";

type HeaderLogoProps = {
    children?: ReactNode;
    logoSrc?: string;
    warning?: boolean;
    style?: string[];
};

export const HeaderLogo: FC<HeaderLogoProps> = ({
    children,
    logoSrc,
    warning = false,
    style = []
}) => {
    const classes = useMemo(
        () => ["header-logo", warning ? "warning" : "", ...style].join(" "),
        [warning, style]
    );
    return (
        <Link to="/">
            <span className={classes}>
                {logoSrc ? <img className="image" src={logoSrc} /> : ""}
                {children}
            </span>
        </Link>
    );
};

export default HeaderLogo;
