import React, { FC, ReactNode, useMemo } from "react";

import "./list.css";

type ListProps = {
    elements: ReactNode[];
    style?: string[];
};

export const List: FC<ListProps> = ({ elements, style = [] }) => {
    const classes = useMemo(() => ["list", ...style].join(" "), [style]);
    return (
        <ul className={classes}>
            {elements.map((e, i) => (
                <li className="item" key={i}>
                    {e}
                </li>
            ))}
        </ul>
    );
};

export default List;
