import React, { FC, ReactNode, useMemo } from "react";

import "./label.css";

type LabelProps = {
    children: ReactNode;
    align?: "left" | "right" | "center";
    underline?: boolean;
    style?: string[];
};

export const Label: FC<LabelProps> = ({
    children,
    align,
    underline = false,
    style = []
}) => {
    const classes = useMemo(
        () => ["label", underline ? "underline" : "", ...style].join(" "),
        [underline, style]
    );
    return (
        <label className={classes} style={{ textAlign: align }}>
            {children}
        </label>
    );
};

export default Label;
