import { Repair } from "omni-neo-api";
import React, { FC, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import {
    ContentHeader,
    InputPair,
    Label,
    Link,
    Separator,
    Tag
} from "../../..";
import { DataContext, OmniAPIContext } from "../../../../contexts";

import "./repairs-show.css";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const require: any;

type RepairsShowProps = {
    style?: string[];
};

export const RepairsShow: FC<RepairsShowProps> = ({ style = [] }) => {
    const classes = useMemo(
        () => ["repairs-show", ...style].join(" "),
        [style]
    );

    const { id } = useParams();
    const api = useContext(OmniAPIContext);
    const { data, setData, setReloadData } = useContext(DataContext);
    const repair = data as Repair;

    useEffect(() => {
        const fetchData = async () => {
            const repair = await api.getRepair(Number(id));
            setData?.(repair);
        };
        setReloadData?.(() => fetchData);
        fetchData();
    }, [api, id, setData, setReloadData]);

    return (
        <div className={classes}>
            <ContentHeader
                title={id ? `REPAL${id}` : "REPALNOID"}
                subTitle="2023-02-17 17:26:36"
                avatarSrc={require("./res/avatar-big.png")}
            />
            <InputPair label="Title">
                <Label style={["light"]}>{repair?.title}</Label>
            </InputPair>
            <InputPair label="Status">
                <Label>
                    <Tag color="green">{repair?.status}</Tag>
                </Label>
            </InputPair>
            <InputPair label="Customer">
                <Label style={["light"]}>
                    <Link href="#">{repair?.customer}</Link>
                </Label>
            </InputPair>
            <InputPair label="Employee">
                <Label style={["light"]}>
                    <Link href="#">{repair?.employee}</Link>
                </Label>
            </InputPair>
            <Separator />
            <InputPair left={<Label align="right">Item Reference</Label>}>
                <Label style={["light"]}>{repair?.item_reference}</Label>
            </InputPair>
            <InputPair left={<Label align="right">Sale Identifier</Label>}>
                <Label style={["light"]}>{repair?.sale_identifier}</Label>
            </InputPair>
            <InputPair left={<Label align="right">Repair Type</Label>}>
                <Label style={["light"]}>{repair?.repair_type}</Label>
            </InputPair>
            <Separator />
            <InputPair left={<Label align="right">Item Damage</Label>}>
                <Label style={["light"]}>{repair?.item_damage}</Label>
            </InputPair>
            <InputPair left={<Label align="right">Problem Desc.</Label>}>
                <Label style={["light"]}>{repair?.problem_description}</Label>
            </InputPair>
            <InputPair left={<Label align="right">Description</Label>}>
                <Label style={["light"]}>{repair?.description}</Label>
            </InputPair>
            <Separator />
            <InputPair left={<Label align="right">Imported</Label>}>
                <Label style={["light"]}>
                    {(repair?.imported ?? false) ? "Yes" : "No"}
                </Label>
            </InputPair>
            {(repair?.imported ?? false) && (
                <InputPair left={<Label align="right">Import Date</Label>}>
                    <Label style={["light"]}>
                        {String(new Date((repair?.import_date ?? 0) * 1000))}
                    </Label>
                </InputPair>
            )}
            {repair?.reference_oid && (
                <InputPair left={<Label align="right">Reference ID</Label>}>
                    <Label style={["light"]}>{repair?.reference_oid}</Label>
                </InputPair>
            )}
        </div>
    );
};

export default RepairsShow;
