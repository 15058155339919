import React, { FC, ReactNode, useMemo } from "react";

import "./viewport.css";

type ViewportStyle = "dark" | "centered";

type ViewportProps = {
    children?: ReactNode;
    width?: string | number;
    style?: ViewportStyle[];
};

export const Viewport: FC<ViewportProps> = ({
    children,
    width,
    style = []
}) => {
    const classes = useMemo(() => ["viewport", ...style].join(" "), [style]);
    return (
        <div className={classes} style={{ width: width }}>
            {children}
        </div>
    );
};

export default Viewport;
