import React, { FC, ReactNode, useMemo } from "react";

import "./tag.css";

type TagProps = {
    children?: ReactNode;
    color: "grey" | "green" | "orange" | "red" | "blue";
    style?: string[];
};

export const Tag: FC<TagProps> = ({ children, color, style = [] }) => {
    const classes = useMemo(
        () => ["tag", color ? color : "", ...style].join(" "),
        [color, style]
    );
    return <span className={classes}>{children}</span>;
};

export default Tag;
