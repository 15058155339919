import React, { FC, HTMLInputTypeAttribute, useMemo } from "react";

import "./text-input.css";

type TextInputProps = {
    placeholder?: string;
    type?: HTMLInputTypeAttribute;
    size?: "medium" | "large";
    width?: string | number;
    style?: string[];
    onChange?: (value: string) => void;
};

export const TextInput: FC<TextInputProps> = ({
    placeholder,
    type = "text",
    size,
    width,
    style = [],
    onChange
}) => {
    const classes = useMemo(
        () => ["text-input", size ? size : "", ...style].join(" "),
        [size, style]
    );
    return (
        <input
            type={type}
            className={classes}
            style={{ width: width }}
            placeholder={placeholder}
            onChange={(e) => onChange && onChange(e.target.value)}
        />
    );
};

export default TextInput;
