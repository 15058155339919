import React, { FC, ReactNode, useMemo } from "react";

import "./content.css";

type ContentProps = {
    children?: ReactNode;
    style?: string[];
};

export const Content: FC<ContentProps> = ({ children, style = [] }) => {
    const classes = useMemo(() => ["content", ...style].join(" "), [style]);
    return <div className={classes}>{children}</div>;
};

export default Content;
