import React, { FC, useMemo, useState } from "react";

import { RadioPair } from "../..";

import "./radio-group.css";

type RadioGroupItem = {
    value: string;
    text?: string;
    checked?: boolean;
};

type RadioGroupProps = {
    items: RadioGroupItem[];
    name?: string;
    style?: string[];
    onChange?: (selected: RadioGroupItem, items: RadioGroupItem[]) => void;
};

export const RadioGroup: FC<RadioGroupProps> = ({
    items,
    name,
    style = [],
    onChange
}) => {
    const classes = useMemo(() => ["radio-group", ...style].join(" "), [style]);
    const [index, setIndex] = useState<number>(0);
    const [itemsState, setItemsState] = useState<RadioGroupItem[]>(items);
    const _onChange = (item: RadioGroupItem, checked: boolean) => {
        if (item.checked === checked) {
            return;
        }
        itemsState.forEach((i) => (i.checked = false));
        item.checked = checked;
        setItemsState(itemsState);
        setIndex(index + 1);
        onChange && onChange(item, itemsState);
    };
    return (
        <div className={classes}>
            {itemsState.map((i, ix) => {
                return (
                    <RadioPair
                        key={ix}
                        name={name}
                        checked={i.checked ?? false}
                        managed={false}
                        onChange={(e) => _onChange(i, e)}
                    >
                        {i.text ?? i.value}
                    </RadioPair>
                );
            })}
        </div>
    );
};

export default RadioGroup;
