import React, { FC, ReactNode, useMemo } from "react";

import "./text.css";

type TextProps = {
    children: ReactNode;
    style?: string[];
};

export const Text: FC<TextProps> = ({ children, style = [] }) => {
    const classes = useMemo(() => ["text", ...style].join(" "), [style]);
    return <div className={classes}>{children}</div>;
};

export default Text;
