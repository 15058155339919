import React, { FC, useMemo } from "react";

import { ContentHeader, Link, List, Text } from "../..";

import "./home.css";

type HomeProps = {
    style?: string[];
};

export const Home: FC<HomeProps> = ({ style = [] }) => {
    const classes = useMemo(() => ["home", ...style].join(" "), [style]);
    return (
        <div className={classes}>
            <ContentHeader title={"Homepage"} style={["compressed"]} />
            <Text>
                <List
                    elements={[
                        <Link to="/repairs">Repairs List</Link>,
                        <Link to="/repairs/new">Repairs Create</Link>,
                        <Link to="/repairs/1">Repairs Show</Link>
                    ]}
                />
            </Text>
        </div>
    );
};

export default Home;
