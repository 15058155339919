import React, {
    FC,
    HTMLAttributeAnchorTarget,
    ReactNode,
    useMemo
} from "react";
import { Link } from "react-router-dom";

import "./button.css";

type ButtonProps = {
    children: ReactNode;
    to?: string;
    href?: string;
    disabled?: boolean;
    target?: HTMLAttributeAnchorTarget;
    width?: string | number;
    style?: string[];
    onClick?: () => void;
};

export const Button: FC<ButtonProps> = ({
    children,
    to,
    href,
    disabled = false,
    target,
    width,
    style = [],
    onClick
}) => {
    const classes = useMemo(
        () =>
            [
                to ? "button-container" : "button",
                disabled ? "disabled" : "",
                ...style
            ].join(" "),
        [to, disabled, style]
    );
    return to ? (
        <span className={classes} onClick={onClick} style={{ width: width }}>
            <Link className="button" to={to} target={target}>
                {children}
            </Link>
        </span>
    ) : (
        <a
            className={classes}
            href={href}
            target={target}
            onClick={onClick}
            style={{ width: width }}
        >
            {children}
        </a>
    );
};

export default Button;
