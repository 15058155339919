import React, { FC, ReactNode, useMemo } from "react";

import "./quick-action.css";

type QuickActionProps = {
    children?: ReactNode;
    title?: string;
    style?: string[];
};

export const QuickAction: FC<QuickActionProps> = ({
    children,
    title,
    style = []
}) => {
    const classes = useMemo(
        () => ["quick-action", ...style].join(" "),
        [style]
    );
    return (
        <div className={classes}>
            {title ? <div className="header">{title}</div> : ""}
            {children}
        </div>
    );
};

export default QuickAction;
