import React, { FC, useMemo, useState } from "react";

import "./radio-field.css";

type RadioFieldProps = {
    name?: string;
    value?: string;
    checked?: boolean;
    managed?: boolean;
    style?: string[];
    onChange?: (checked: boolean) => void;
};

export const RadioField: FC<RadioFieldProps> = ({
    name,
    value,
    checked = false,
    managed = true,
    style = [],
    onChange
}) => {
    const classes = useMemo(() => ["radio-field", ...style].join(" "), [style]);
    const [checkedState, setCheckedState] = useState<boolean>(checked);
    return (
        <input
            type="radio"
            name={name}
            value={value}
            checked={managed ? checkedState : checked}
            className={classes}
            onChange={(e) => {
                setCheckedState(e.target.checked);
                onChange && onChange(e.target.checked);
            }}
        />
    );
};

export default RadioField;
