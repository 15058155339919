import React, { FC, ReactNode, useMemo } from "react";

import { Label } from "../..";

import "./input-pair.css";

type InputPairProps = {
    children?: ReactNode;
    left?: ReactNode;
    leftWidth?: string | number;
    label?: string;
    value?: string;
    mandatory?: boolean;
    style?: string[];
};

export const InputPair: FC<InputPairProps> = ({
    children,
    left,
    leftWidth = "104px",
    label,
    value,
    mandatory = false,
    style = []
}) => {
    const classes = useMemo(
        () => ["input-pair", mandatory ? "mandatory" : "", ...style].join(" "),
        [mandatory, style]
    );
    return (
        <div className={classes}>
            <div className="left" style={{ width: leftWidth }}>
                {label ? (
                    <Label align="right" underline={mandatory}>
                        {label}
                    </Label>
                ) : (
                    left
                )}
            </div>
            <div className="right">
                {value ? <Label>{value}</Label> : children}
            </div>
        </div>
    );
};

export default InputPair;
