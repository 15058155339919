import React, { FC, ReactNode, useMemo } from "react";

import "./context-bar.css";

type ContextBarProps = {
    children?: ReactNode;
    style?: string[];
};

export const ContextBar: FC<ContextBarProps> = ({ children, style = [] }) => {
    const classes = useMemo(() => ["context-bar", ...style].join(" "), [style]);
    return <div className={classes}>{children}</div>;
};

export default ContextBar;
