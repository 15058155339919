import React, { FC, useMemo } from "react";

import "./image.css";

type ImageStyle = "large";

type ImageProps = {
    src?: string;
    alt?: string;
    width?: string | number;
    height?: string | number;
    style?: ImageStyle[];
};

export const Image: FC<ImageProps> = ({
    src,
    alt,
    width,
    height,
    style = []
}) => {
    const classes = useMemo(() => ["image", ...style].join(" "), [style]);
    return (
        <img
            src={src}
            alt={alt}
            className={classes}
            style={{ width: width, height: height }}
        />
    );
};

export default Image;
