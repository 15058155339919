import React, { FC, useMemo } from "react";

import "./avatar.css";

type AvatarProps = {
    src: string;
    style?: string[];
};

export const Avatar: FC<AvatarProps> = ({ src, style = [] }) => {
    const classes = useMemo(() => ["avatar", ...style].join(" "), [style]);
    return (
        <div className={classes}>
            <img className="image" src={src} />
        </div>
    );
};

export default Avatar;
