import React, { FC, ReactNode, useMemo, useState } from "react";

import { RadioField } from "../..";

import "./radio-pair.css";

type RadioPairProps = {
    children: ReactNode;
    name?: string;
    value?: string;
    checked?: boolean;
    managed?: boolean;
    style?: string[];
    onChange?: (checked: boolean) => void;
};

export const RadioPair: FC<RadioPairProps> = ({
    children,
    name,
    value,
    checked = false,
    managed = true,
    style = [],
    onChange
}) => {
    const classes = useMemo(() => ["radio-pair", ...style].join(" "), [style]);
    const [checkedState, setCheckedState] = useState<boolean>(checked);
    return (
        <div className={classes}>
            <RadioField
                name={name}
                value={value}
                checked={managed ? checkedState : checked}
                managed={false}
                onChange={(c) => {
                    setCheckedState(c);
                    onChange && onChange(c);
                }}
            />
            <div
                className="children"
                onClick={() => {
                    setCheckedState(true);
                    onChange && onChange(true);
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default RadioPair;
