import React, { FC, ReactNode, useMemo, useState } from "react";

import { CheckField } from "../..";

import "./check-pair.css";

type CheckPairProps = {
    children: ReactNode;
    name?: string;
    checked?: boolean;
    managed?: boolean;
    style?: string[];
    onChange?: (checked: boolean) => void;
};

export const CheckPair: FC<CheckPairProps> = ({
    children,
    name,
    checked = false,
    managed = true,
    style = [],
    onChange
}) => {
    const classes = useMemo(() => ["check-pair", ...style].join(" "), [style]);
    const [checkedState, setCheckedState] = useState<boolean>(checked);
    return (
        <div className={classes}>
            <CheckField
                name={name}
                checked={managed ? checkedState : checked}
                managed={false}
                onChange={(c) => {
                    setCheckedState(c);
                    onChange && onChange(c);
                }}
            />
            <div
                className="children"
                onClick={() => {
                    setCheckedState(!checkedState);
                    onChange && onChange(!checkedState);
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default CheckPair;
