import React, { FC, ReactNode, useMemo } from "react";

import "./main-container.css";

type MainContainerProps = {
    children?: ReactNode;
    left?: ReactNode;
    right?: ReactNode;
    style?: string[];
};

export const MainContainer: FC<MainContainerProps> = ({
    children,
    left,
    right,
    style = []
}) => {
    const classes = useMemo(
        () => ["main-container", ...style].join(" "),
        [style]
    );
    return (
        <div className={classes}>
            <div className="main-left">{left}</div>
            <div className="main-center">{children}</div>
            <div className="main-right">{right}</div>
        </div>
    );
};

export default MainContainer;
